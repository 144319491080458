import { render, staticRenderFns } from "./lightbox-nav.vue?vue&type=template&id=d328957a&scoped=true"
import script from "./lightbox-nav.vue?vue&type=script&lang=js"
export * from "./lightbox-nav.vue?vue&type=script&lang=js"
import style0 from "./lightbox-nav.vue?vue&type=style&index=0&id=d328957a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d328957a",
  null
  
)

export default component.exports